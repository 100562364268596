import React, { useContext, useEffect, useRef } from 'react'
import { SharedWebsocketContext } from 'contexts/webSockets'
import { CompatClient } from '@stomp/stompjs/src/compatibility/compat-client'
import { StompSubscription } from '@stomp/stompjs'
import { useThrottledBuffer } from 'tabs/topQuestions/hooks/useThrottledBuffer'
import { clusterDetailsResponseConverter } from 'tabs/topQuestions/conversion/InternalConverters'
import { useDispatch, useSelector } from 'react-redux'
import { selectExceptionally } from 'tabs/topQuestions/utils/ObjectUtils'
import { ClusterDetailsResponse, CoordinatesFilter, FetchClusterQuestions, QuestionDetailsResponse, QuestionsDataResponse } from 'tabs/topQuestions/types/ApiResponses'
import { questionDataResponseConverter, questionDetailsResponseConverter } from 'tabs/topQuestions/conversion/ApiConverters'
import { updateClusteringStatus } from 'tabs/topQuestions/lib/top_questions_config/clusteringConfigurationSlice'
import { UpdateClusteringStatusPayload } from 'tabs/topQuestions/types/InternalTypes'
import { selectActiveBot } from 'tabs/home/reducers/selectors'
import { useDebounceBuffer } from 'tabs/topQuestions/hooks/useDebounceBuffer'
import { newQuestionDataReceived } from 'tabs/topQuestions/lib/questions_chart/questionChartActions'
import { newClusterQuestionsReceived } from 'tabs/topQuestions/lib/questions_content/questionsContentActions'

export const QUESTIONS_PAGE_SIZE = 10

type TopQuestionsProps = {
  onNewQuestionDetailsReceived: (data: QuestionDetailsResponse[]) => void,
  children: (props: {
    sendFetchClusterQuestionsMessage: (request: FetchClusterQuestions) => void,
    sendRequestForQuestionsData: (coordinatesFilter: CoordinatesFilter, questionType: string) => void
    sendQuestionDetailsRequest: (pineconeId: string, mongoId: string) => void,
    loadingData: boolean
  }) => JSX.Element
}

export const QuestionsWebsocketConnector: React.FC<TopQuestionsProps> = ({
                                                                           children,
                                                                           onNewQuestionDetailsReceived,
                                                                         }) => {

  const dispatch = useDispatch()
  const stompSubscriptions = useRef<StompSubscription[]>([])
  const adminUser = useSelector((root: any) => root.adminUser)
  const activeBot = useSelector(selectActiveBot)

  const { getWebsocketClient, websocketConnected } = useContext(SharedWebsocketContext) as any
  const webSocketClient = getWebsocketClient()._client as CompatClient


  useEffect(() => {
    if (webSocketClient.connected && adminUser?.id != null && activeBot?.id != null) {
      stompSubscriptions.current.push(subscribeToQuestionsData())
      stompSubscriptions.current.push(subscribeToQuestionDetails())
      stompSubscriptions.current.push(subscribeToClusterDetails())
      stompSubscriptions.current.push(subscribeClusteringStatusChange())
      return () => {
        stompSubscriptions.current?.forEach(s => s.unsubscribe())
      }
    }
  }, [webSocketClient.connected, adminUser?.id, activeBot?.id])


  const onNewQuestionDataReceived = (data: QuestionsDataResponse[]) => {
    dispatch(newQuestionDataReceived(data))
  }

  const onNewClusterQuestionsPageReceived = (data: ClusterDetailsResponse) => {
    dispatch(newClusterQuestionsReceived(data))
  }

  const [onNewQuestionDataReceivedThrottled, isDebouncing] = useDebounceBuffer(onNewQuestionDataReceived, 1500)
  const onNewQuestionDetailsReceivedThrottled = useThrottledBuffer(onNewQuestionDetailsReceived, 400)

  const subscribeClusteringStatusChange = () => {
    const botId = activeBot.id
    return webSocketClient.subscribe('/topic/bot/' + botId + '/clustering-status', (message) => {
      const payload: UpdateClusteringStatusPayload = JSON.parse(message.body)
      dispatch(updateClusteringStatus(payload))
    })
  }

  const subscribeToQuestionsData = () => {
    const userId = selectExceptionally(adminUser.id)

    return webSocketClient.subscribe('/topic/' + userId + '/question/data', (data) => {
      const questionData = questionDataResponseConverter(data)
      onNewQuestionDataReceivedThrottled(questionData)
    })
  }

  const subscribeToClusterDetails = () => {
    const userId = selectExceptionally(adminUser.id)

    return webSocketClient.subscribe('/topic/' + userId + '/questions-cluster', (data) => {
      const questionData = clusterDetailsResponseConverter(data)
      onNewClusterQuestionsPageReceived(questionData)
    })
  }

  const subscribeToQuestionDetails = () => {
    const userId = selectExceptionally(adminUser.id)

    return webSocketClient.subscribe('/topic/' + userId + '/questions/details', (data) => {
      const questionDetails = questionDetailsResponseConverter(data)
      onNewQuestionDetailsReceivedThrottled(questionDetails)
    })
  }

  function sendQuestionDetailsRequest(pineconeId: string, mongoId: string) {
    console.log('/app/questions-clusters/details')
    const body = JSON.stringify({
                                  pi: pineconeId,
                                  mi: mongoId,
                                })
    webSocketClient.send('/app/questions-clusters/details', {}, body)
  }


  const sendFetchClusterQuestionsMessage = (request: FetchClusterQuestions) => {
    console.log('/app/questions-clusters')
    const body = JSON.stringify(request)
    webSocketClient.send('/app/questions-clusters', {}, body)
  }

  function sendRequestForQuestionsData(coordinatesFilter: CoordinatesFilter,
                                       questionType: string) {
    console.log('/app/request-questions-data')
    const body = JSON.stringify({ coordinatesFilter, questionType })
    webSocketClient.send('/app/request-questions-data', {}, body)
  }

  if (websocketConnected && adminUser?.id) {
    return children({
                      sendRequestForQuestionsData,
                      sendQuestionDetailsRequest,
                      sendFetchClusterQuestionsMessage,
                      loadingData: isDebouncing,
                    })
  } else {
    return <h4>Loading...</h4>
  }
}
