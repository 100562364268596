import { ClusterQuestion } from 'tabs/topQuestions/types/InternalTypes'
import { QuestionsClusterChart } from 'tabs/topQuestions/components/questions_cluster_chart/QuestionsClusterChart'
import { QuestionClustersTab } from 'tabs/topQuestions/components/question_cluster_list/QuestionClustersTab'
import React, { useEffect } from 'react'
import { CoordinatesFilter, FetchClusterQuestions } from 'tabs/topQuestions/types/ApiResponses'
import { QuestionDetailCallback, QuestionDetailsCallbackMap } from 'tabs/topQuestions/components/top_questions_content/TopQuestionsPageContent'
import { CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectQuestionClustersFilter } from 'tabs/topQuestions/lib/top_questions_search/topQuestionsSearchSelectors'
import { selectChartHints } from 'tabs/topQuestions/lib/top_questions/topQuestionsSelectors'
import { selectQuestions } from 'tabs/topQuestions/lib/questions_chart/questionsChartSelectors'


type TopQuestionsDataContentProps = {
  loadingData: boolean
  questionDetailsCallback: QuestionDetailsCallbackMap
  sendFetchClusterQuestionsMessage: (request: FetchClusterQuestions) => void,
  sendRequestForQuestionsData: (coordinatesFilter: CoordinatesFilter, questionType: string) => void
  sendQuestionDetailsRequest: (pineconeId: string, mongoId: string) => void,
}
export const TopQuestionsDataContent: React.FC<TopQuestionsDataContentProps> = ({
                                                                                  loadingData,
                                                                                  questionDetailsCallback,
                                                                                  sendQuestionDetailsRequest,
                                                                                  sendRequestForQuestionsData,
                                                                                  sendFetchClusterQuestionsMessage,
                                                                                }) => {

  const topQuestionsFilter = useSelector(selectQuestionClustersFilter)
  const chartHints = useSelector(selectChartHints)
  const questions = useSelector(selectQuestions)

  useEffect(() => {
    if (questions == null && loadingData == false) {
      sendRequestForQuestionsData(chartHints, topQuestionsFilter.type)
    }
  }, [topQuestionsFilter.type, loadingData])

  const requestQuestionDetails = (data: ClusterQuestion, callback: QuestionDetailCallback) => {
    const { pineconeId, mongoLogId } = data
    if (questionDetailsCallback.hasOwnProperty(pineconeId)) {
      questionDetailsCallback[pineconeId].push(callback)
    } else {
      questionDetailsCallback[pineconeId] = [callback]
    }
    sendQuestionDetailsRequest(pineconeId, mongoLogId)
  }

  return <div className={'topQuestionsPage'}>
    {!loadingData ?
      <QuestionsClusterChart allQuestions={questions}
                             loadingData={loadingData}
                             chartHints={chartHints}
                             requestQuestionDetails={requestQuestionDetails} />
      :
      <div className={'topQuestionsChartLoadingWrapper'}>
        <CircularProgress />
      </div>
    }
    <div className={'topQuestionsControlsWrapper'}>
      <QuestionClustersTab sendFetchClusterQuestionsMessage={sendFetchClusterQuestionsMessage} />
    </div>
  </div>

}
