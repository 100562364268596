import { ClusterDetails, ClusterDetailsState, GenerateClusterNameResponsePayload } from 'tabs/topQuestions/types/InternalTypes'
import { PendingRequest, RequestStatus } from 'tabs/topQuestions/types/GenericTypes'
import { ChartHints } from 'tabs/topQuestions/types/ApiResponses'

import { TopQuestionsSlice } from 'tabs/topQuestions/lib/top_questions/topQuestionsConstnats'

export const selectTopQuestionsRequest = (store: any): RequestStatus => {
  const topQuestionsState: TopQuestionsSlice = store.topQuestions
  return topQuestionsState.fetchTopQuestionsRequestStatus
}
export const selectClusters = (store: any): ClusterDetailsState => {
  const topQuestionsState: TopQuestionsSlice = store.topQuestions
  return topQuestionsState.clusters
}
export const selectChartHints = (store: any): ChartHints | undefined => {
  const topQuestionsState: TopQuestionsSlice = store.topQuestions
  return topQuestionsState.chartHints
}

export const selectClusterDisplayedStatus = (clusterId: string) => {
  return (store: any): boolean => {
    const topQuestionsState: TopQuestionsSlice = store.topQuestions
    const cluster = topQuestionsState.clusters[clusterId]
    if (cluster != null) {
      return cluster.isDisplayed
    } else {
      console.error('Cluster not found ' + clusterId)
      return false
    }
  }
}
export const selectClusterDetails = (store: any): ClusterDetails[] => {
  const topQuestionsState: TopQuestionsSlice = store.topQuestions
  const { clusters } = topQuestionsState
  return Object.values(clusters)
}
export const selectGenerateClusterNameRequest = (clusterId: string) => {
  return (store: any): PendingRequest<GenerateClusterNameResponsePayload> | undefined => {
    const topQuestionsState: TopQuestionsSlice = store.topQuestions
    return topQuestionsState.generateNameRequest[clusterId]
  }
}
