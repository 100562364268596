import { BACKEND_URL } from 'configs'

export const unknownQuestionsAPIMessages = {
  deleted: 'Question deleted successfully',
  trained: 'Question trained successfully',
  updated: 'Question updated successfully',
  created: 'Question created successfully',
  createdAndTrained: 'Question created and trained successfully',
}

export const abbreviationsAPIMessages = {
  deleted: 'Abbreviation deleted successfully',
  trained: 'Abbreviation trained successfully',
  updated: 'Abbreviation updated successfully',
  created: 'Abbreviation created successfully',
  createdFromFile: 'Abbreviations uploaded from file successfully',
  createdAndTrained: 'Abbreviation created and trained successfully',
}

export const feedbackAPIMessages = {
  deleted: 'User feedback deleted successfully',
  solved: 'User feedback solved successfully',
  trained: 'Question retrained successfully',
  created: 'New question created successfully',
}

export const getApiUrlTemplateFunc = (templatePath: string) => {
  return (path = '', params = {}) => {
    const searchParams = new URLSearchParams(params)
    return `${BACKEND_URL}/${templatePath}/${path}?${searchParams}`
  }
}

export const botSettingMessages = {
  changed: 'User feedback activity changed successfully!',
  groupRemoved: 'User removed from group successfully!',
}
