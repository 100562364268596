import { useRef, useState } from 'react'

export const useDebounce = <U extends any[]>(fn: (...args: U) => void,
                                             debounceValue = 500): [(...args: U) => void, boolean] => {

  const throttleTimeoutRef = useRef<NodeJS.Timeout | undefined>()
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false)

  function scheduleNextExecution(args: U, time: number) {
    setIsDebouncing(true)
    throttleTimeoutRef.current = setTimeout(() => {
      fn(...args)
      setIsDebouncing(false)
      throttleTimeoutRef.current = undefined
    }, time)
  }

  const debouncedFunction = (...args: U) => {
    const throttleTimeout = throttleTimeoutRef.current
    if (throttleTimeout == null) {
      scheduleNextExecution(args, debounceValue)
    } else {
      clearTimeout(throttleTimeout)
      scheduleNextExecution(args, debounceValue)
    }
  }

  return [debouncedFunction, isDebouncing]

}
