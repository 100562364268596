import React from 'react'

import KnowledgeBase from '../tabs/nlp/components/knowledgeBase/KnowledgeBase'
import UnansweredQuestions from '../tabs/nlp/components/unansweredQuestions'
import AbbreviationTab from '../tabs/nlp/components/Abbreviations'
import UserFeedbackTab from '../tabs/nlp/components/UserFeedback'
import { UnknownQuestionProvider } from 'tabs/nlp/components/unansweredQuestions/context'
import { AbbreviationsProvider } from 'tabs/nlp/components/Abbreviations/context'
import { UserFeedbackProvider } from 'tabs/nlp/components/UserFeedback/context'
import { TopQuestionsPage } from 'tabs/topQuestions/components/top_questions_page/TopQuestionsPage'

type nlpRoute = {
  path: string
  name: string
  component: () => JSX.Element
}

export const nlpRoutes: nlpRoute[] = [
  {
    path: 'ai-knowledge',
    name: 'AI Knowledge',
    component: () => <KnowledgeBase />,
  },
  {
    path: 'unanswered-questions',
    name: 'Unanswered Questions',
    component: () => (
      <UnknownQuestionProvider>
        <UnansweredQuestions />
      </UnknownQuestionProvider>
    ),
  },
  {
    path: 'abbreviations',
    name: 'Abbreviations',
    component: () => (
      <AbbreviationsProvider>
        <AbbreviationTab />
      </AbbreviationsProvider>
    ),
  },
  {
    path: 'feedback',
    name: 'User Feedback',
    component: () => (
      <UserFeedbackProvider>
        <UserFeedbackTab />
      </UserFeedbackProvider>
    ),
  },
  {
    path: 'top-questions',
    name: 'Top Questions',
    component: () => <TopQuestionsPage />,
  },
]
