import { useBuffer } from 'hooks/useBuffer'
import { useDebounce } from 'tabs/topQuestions/hooks/useDebounce'

// Argument function that is called with
type BufferSubscriber<U> = (events: U[]) => void

export const useDebounceBuffer = <U, >(
  bufferSubscriber: BufferSubscriber<U>,
  throttle = 100): [(event: U) => void, boolean] => {

  const buffer = useBuffer(bufferSubscriber)
  const [debouncedReleaseSignal, isDebouncing] = useDebounce(buffer.releaseSignal, throttle)


  const debouncedFunc = (e: U) => {
    debouncedReleaseSignal()
    buffer.append(e)
  }

  return [debouncedFunc, isDebouncing]
}
