import { PayloadAction, PendingRequest, RequestStatus } from 'tabs/topQuestions/types/GenericTypes'
import {
  ClusterDetailsState,
  GenerateClusterNameRejectedPayload,
  GenerateClusterNameReqPayload,
  GenerateClusterNameResponsePayload,
  UpdateClusterHoveredPayload,
  UpdateClusterSelectedPayloadAction,
} from 'tabs/topQuestions/types/InternalTypes'
import { ChartHints, TopQuestionsErrorResponse, TopQuestionsResponse } from 'tabs/topQuestions/types/ApiResponses'

export type TopQuestionsSlice = {
  fetchTopQuestionsRequestStatus: RequestStatus
  clusters: ClusterDetailsState
  chartHints?: ChartHints
  generateNameRequest: Record<string, PendingRequest<GenerateClusterNameResponsePayload>>
}
// FETCH CLUSTERS START
export const FETCH_TOP_QUESTIONS_PENDING = 'topQuestions/FETCH_TOP_QUESTIONS_PENDING'
export const FETCH_TOP_QUESTIONS_RESOLVED = 'topQuestions/FETCH_TOP_QUESTIONS_RESOLVED'
export const FETCH_TOP_QUESTIONS_REJECTED = 'topQuestions/FETCH_TOP_QUESTIONS_REJECTED'
export const UPDATE_DISPLAYED_CLUSTER = 'topQuestions/UPDATE_DISPLAYED_CLUSTER'
export const UPGRADE_TRAIN_CONFIGURATION = 'topQuestions/UPGRADE_TRAIN_CONFIGURATION'
export const UPDATE_CLUSTER_HOVERED = 'topQuestions/UPDATE_CLUSTER_HOVERED'
// GENERATE NAME START
export const GENERATE_CLUSTER_NAME_REQ = 'topQuestions/GENERATE_CLUSTER_NAME_REQ'
export const GENERATE_CLUSTER_NAME_RESOLVED = 'topQuestions/GENERATE_CLUSTER_NAME_RESOLVED'
export const GENERATE_CLUSTER_NAME_REJECTED = 'topQuestions/GENERATE_CLUSTER_NAME_REJECTED'

export type FetchTopQuestionsRejected = PayloadAction<typeof FETCH_TOP_QUESTIONS_REJECTED, TopQuestionsErrorResponse>
export type FetchTopQuestionsResolved = PayloadAction<typeof FETCH_TOP_QUESTIONS_RESOLVED, TopQuestionsResponse>
export type FetchTopQuestionsPending = PayloadAction<typeof FETCH_TOP_QUESTIONS_PENDING, undefined>
export type UpdateClusterDisplayedStatus = PayloadAction<typeof UPDATE_DISPLAYED_CLUSTER, UpdateClusterSelectedPayloadAction>
export type GenerateClusterNameReq = PayloadAction<typeof GENERATE_CLUSTER_NAME_REQ, GenerateClusterNameReqPayload>
export type GenerateClusterNameResolved = PayloadAction<typeof GENERATE_CLUSTER_NAME_RESOLVED, GenerateClusterNameResponsePayload>
export type GenerateClusterNameRejected = PayloadAction<typeof GENERATE_CLUSTER_NAME_REJECTED, GenerateClusterNameRejectedPayload>
export type UpdateClusterHovered = PayloadAction<typeof UPDATE_CLUSTER_HOVERED, UpdateClusterHoveredPayload>

export type TopQuestionPayloadAction = FetchTopQuestionsRejected
  | FetchTopQuestionsResolved
  | FetchTopQuestionsPending
  | UpdateClusterDisplayedStatus
  | GenerateClusterNameReq
  | GenerateClusterNameResolved
  | GenerateClusterNameRejected
  | UpdateClusterHovered
