/* eslint-disable */
import React, { FC } from 'react'
import { connect } from 'react-redux'

import PaperHeader from '../PaperHeader'

import { FunnelType } from 'models/FunnelTypes'

import * as S from './style'

const LookerStudioEmbeddingWrap: FC = ({}) => {
  const reportUrl = process.env.REACT_APP_LOOKER_STUDIO_REPORT_URL || ''

  return (
    <S.Container>
      <PaperHeader title="Simon Bot Report" />
      <S.TableWrap>
        {reportUrl ? (
          <iframe
            height="800"
            width="100%"
            src={reportUrl}
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          />
        ) : (
          'Report is not available'
        )}
      </S.TableWrap>
    </S.Container>
  )
}

const mapStateToProps = (state: { funnels: FunnelType[]; activeBot: any }) => ({
  funnels: state.funnels,
  botId: state.activeBot?.id,
})

export default connect(mapStateToProps)(LookerStudioEmbeddingWrap)
