import { Chart, ChartData } from 'chart.js'
// import zoomPlugin from 'chartjs-plugin-zoom'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { ClusterDetailsState, ClusterQuestion } from 'tabs/topQuestions/types/InternalTypes'
import { ChartHints, QuestionDetails } from 'tabs/topQuestions/types/ApiResponses'
import _ from 'lodash'
import { selectClusters } from 'tabs/topQuestions/lib/top_questions/topQuestionsSelectors'
import './QuestionsClusterChart.scss'

type QuestionsClusterChartProps = {
  chartHints: ChartHints
  allQuestions: Partial<ClusterQuestion>[] | null,
  loadingData: boolean
  requestQuestionDetails: (data: ClusterQuestion, callback: (details: QuestionDetails) => void) => void
}

function clusterDisplayed(q: Partial<ClusterQuestion>, clusters: ClusterDetailsState) {
  const mongoClusterId = q.mongoClusterId
  if (mongoClusterId != undefined) {
    const clus = clusters[mongoClusterId]
    if (clus != undefined) {
      return clus.isDisplayed
    } else {
      console.warn('Cluster not found: ' + mongoClusterId + ' ' + q.pineconeId)
    }
  } else {
    console.warn('cluster id is null: ' + q.pineconeId)
  }
  return false
}

type ChartDatasetItem = ClusterQuestion & { questionContent: string }

export const QuestionsClusterChart: React.FC<QuestionsClusterChartProps> =
  ({ loadingData, requestQuestionDetails, allQuestions, chartHints }) => {

    const chartRef = useRef<Chart>(null)
    const clusters = useSelector(selectClusters)
    // const allClusterIds = Object.keys(clusters) as unknown as string[]
    const displayedClustersLen = Object.values(clusters).filter(c => c.isDisplayed).length
    const htmlCanvasElement = useRef<HTMLCanvasElement | null>(null)
    const chartOptionIsHoveredIdx = Object.values(clusters)
      .findIndex(c => c.hovered)

    const numberOfQuestions = allQuestions == null ? 0 : allQuestions.length

    const chartData = allQuestions == null ? [] :
      allQuestions.filter(q => q.x != undefined && q.y != undefined)

    const chartDataLength = chartData.length


    useEffect(() => {
      console.log('Number of questions changed')
      if (!loadingData && chartRef.current != null) {
        chartRef.current.data = buildChartDatasets()
        chartRef.current.update()
      }
    }, [loadingData, chartDataLength, displayedClustersLen, (chartRef.current != null), chartOptionIsHoveredIdx])

    const buildChartDatasets = (): ChartData => {
      const displayedQuestions = chartData
        .filter(q => clusterDisplayed(q, clusters))
      const clusterQuestions = _.groupBy(displayedQuestions, 'mongoClusterId')

      const chartOptionIsHovered = chartOptionIsHoveredIdx != -1
      const datasets = Object.keys(clusterQuestions)
        .map(clusterId => {
          const cluster = clusters[clusterId]
          const questions = clusterQuestions[clusterId]
          const clusterColor = cluster.clusterColor
          const backgroundColor = (!chartOptionIsHovered) ? clusterColor :
            (cluster.hovered ? clusterColor : '#9f9f9f')
          return {
            label: 'Group: ' + cluster.clusterId,
            data: questions,
            backgroundColor,
          }
        })
      return { datasets }
    }

    useEffect(() => {
      if (htmlCanvasElement.current) {
        if (chartRef.current == null && chartHints && numberOfQuestions > 0) {
          initializeChart()
        }
      }
    }, [htmlCanvasElement.current, numberOfQuestions])

    function initializeChart() {
      const datasets = buildChartDatasets()
      // const { minX, maxX, minY, maxY } = chartHints

      chartRef.current = new Chart(htmlCanvasElement.current, {
        type: 'scatter',
        data: datasets,
        options: {
          scales: {
            yAxes: [{
              ticks: { display: false },
            }],
            xAxes: [{
              ticks: { display: false },
            }],
          },
          animation: { duration: 0 },
          legend: { display: false },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                const { index, datasetIndex } = tooltipItem
                const dataPoint: ChartDatasetItem = data.datasets[datasetIndex].data[index] as ChartDatasetItem
                if (dataPoint.questionContent) {
                  return dataPoint.questionContent
                } else {
                  dataPoint.questionContent = ''
                  requestQuestionDetails(dataPoint, (details) => {
                    dataPoint.questionContent = details.questionText
                    chartRef.current.update()
                  })
                }
                return 'Loading...'
              },
            },
          },
        },
      })
      chartRef.current.render()
    }

    return <div className={'questionClusterChartWrapper'}>
      <canvas height={280} ref={htmlCanvasElement} />
    </div>
  }
