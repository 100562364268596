import { QuestionsWebsocketConnector } from 'tabs/topQuestions/components/questions_websocket_connector/QuestionsWebsocketConnector'
import React, { useRef } from 'react'
import { QuestionDetails, QuestionDetailsResponse } from 'tabs/topQuestions/types/ApiResponses'
import { TopQuestionsDataContent } from 'tabs/topQuestions/components/top_questions_data_content/TopQuestionsDataContent'

export type QuestionDetailCallback = (details: QuestionDetails) => void

export type QuestionDetailsCallbackMap = Record<string, QuestionDetailCallback[]>
export const TopQuestionsPageContent = () => {

  const questionDetailsCallback = useRef<QuestionDetailsCallbackMap>({})


  const onNewQuestionDetailsReceived = (data: QuestionDetailsResponse[]) => {
    for (const questionDetails of data) {
      const { pineconeId } = questionDetails
      const callbacks = questionDetailsCallback.current
      const dataPointCallbacks = callbacks[pineconeId]
      callbacks[pineconeId] = []
      dataPointCallbacks.forEach(call => call(questionDetails.details))
    }
  }

  return <QuestionsWebsocketConnector onNewQuestionDetailsReceived={onNewQuestionDetailsReceived}>
    {({ sendRequestForQuestionsData, sendQuestionDetailsRequest, sendFetchClusterQuestionsMessage, loadingData }) => {

      return <TopQuestionsDataContent sendRequestForQuestionsData={sendRequestForQuestionsData}
                                      questionDetailsCallback={questionDetailsCallback.current}
                                      sendQuestionDetailsRequest={sendQuestionDetailsRequest}
                                      sendFetchClusterQuestionsMessage={sendFetchClusterQuestionsMessage}
                                      loadingData={loadingData} />
    }}
  </QuestionsWebsocketConnector>
}
