import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { StyledComponentProps } from '@material-ui/core/styles/withStyles'
import { selectActiveBot } from 'tabs/home/reducers/selectors'
import LoadingButton from '@mui/lab/LoadingButton'
import { RequestStatus } from 'tabs/topQuestions/types/GenericTypes'
import AnimationIcon from '@mui/icons-material/Animation'
import { selectClusteringRequestStatus, selectClusteringSensitivityConfiguration } from 'tabs/topQuestions/lib/top_questions_config/clusteringConfigurationSlice'
import { fetchCurrentClusteringStatus, startClustering } from 'tabs/topQuestions/lib/top_questions_config/clusteringConfigurationApi'

type QuestionsControlsProps = StyledComponentProps

const styles = (theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(10),
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.typography.pxToRem(8),
  },
  pleaseWait: {
    fontSize: theme.typography.pxToRem(12),
  },
})

// @ts-ignore
export const ClusteringControls = withStyles(styles)(
  ({ classes }: QuestionsControlsProps) => {

    const dispatch = useDispatch()
    const requestStatus = useSelector(selectClusteringRequestStatus)
    const selectedSensitivity = useSelector(selectClusteringSensitivityConfiguration)
    const activeBot = useSelector(selectActiveBot)

    const activeBotId = activeBot.id

    const isTrainingInProgress = requestStatus == RequestStatus.PENDING
    const isTrainingStartFailed = requestStatus == RequestStatus.REJECTED

    useEffect(() => {
      dispatch(fetchCurrentClusteringStatus(activeBotId))
    }, [])

    const onStartClusteringClick = () => {
      dispatch(startClustering(activeBotId, selectedSensitivity))
    }

    return <div className={classes.cardActions}>
      <LoadingButton
        loading={isTrainingInProgress}
        loadingPosition="start"
        startIcon={<AnimationIcon />}
        variant="outlined"
        onClick={onStartClusteringClick}>
        {isTrainingInProgress ? 'Training...' : 'Begin Training'}
      </LoadingButton>
      {isTrainingInProgress &&
        <Typography color={'textSecondary'} className={classes.pleaseWait} variant={'subheading'}>
          Please wait, training might take a couple of minutes
        </Typography>}
      {isTrainingStartFailed &&
        <Typography color={'error'} variant={'subheading'}>
          Failed to start training
        </Typography>}
    </div>
  })
