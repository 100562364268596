export type ClusterDetailsResponse = {
  clusterId: string
  page: number
  pageSize: number
  questions: ClusterQuestionResponse[]
}

export type ClusterQuestionResponse = {
  logId: string
  questionContent: string | undefined
}

export type GenerateTopQuestionNameResponse = { generatedName: string }
export type FetchClusterQuestions = { clusterId: string, typeFilter: string, page: number, pageSize: number }

export const dataFields = {
  pineconeId: 'pId',
  mongoLogId: 'mId',
  mongoClusterId: 'l',
  x: 'x',
  y: 'y',
}
export const detailsResponseFields = {
  pineconeId: 'pi',
  details: 'd',
  error: 'e',
}
export const detailsFields = {
  questionText: 't',
}

export type QuestionDetailsResponse = {
  pineconeId: string
  details: QuestionDetails
  error: string
}

export type QuestionDetails = {
  questionText: string
}

export type QuestionsDataResponse = {
  pineconeId: string
  mongoLogId: string
  mongoClusterId: string
  x: number
  y: number
}

export type CoordinatesFilter = {
  minX: number
  maxX: number
  minY: number
  maxY: number
}

export type TopQuestionsResponse = {
  chartHints: ChartHints
  topQuestions: TopQuestion[]
}

export type TopQuestionsErrorResponse = { errorMessage: string }

export type ChartHints = {
  minX: number
  maxX: number
  minY: number
  maxY: number
}

export type TopQuestion = {
  id: string
  questionsCount: number
  faqCount: number
  daxCount: number
  unknownCount: number
  questionName: string
}

export type StartClusteringResponse = {
  errorMessage: string
}

export type FetchClusteringStatusResponse = {
  status: boolean
}

export type ExcludeFromClusteringResponse = {
  clusterId?: string
  error?: string
}
