import { BACKEND_URL } from '../../../configs'
import API from '../../../services/api'
import { alertSuccess } from '../../../api'
import { AbbreviationModel } from '../components/Abbreviations/type'
import { AbbreviationCreateAPI, DefaultSearchAPI } from './types'
import { abbreviationsAPIMessages } from './configs'
import { SimpleObject } from 'types/types'

const template = (path = '', params = {}) => {
  const searchParams = new URLSearchParams(params)
  return `${BACKEND_URL}/abbreviation${path}?${searchParams}`
}

const searchAbbreviations = async (params: DefaultSearchAPI): Promise<AbbreviationModel[]> => {
  return API.get(template('', params))
}

const createNewAbbreviation = async (record: AbbreviationCreateAPI): Promise<any> => {
  return API.post(template(''), record).then(response => {
    alertSuccess(abbreviationsAPIMessages.created)
    return response
  })
}

const uploadAbbreviationFromFile = async (body: FormData): Promise<any> => {
  return fetch(template('/file'), {
    credentials: 'include',
    method: 'POST',
    body: body,
  }).then(response => {
    alertSuccess(abbreviationsAPIMessages.createdFromFile)
    return response
  })
}

const deleteAbbreviation = async (id: string): Promise<any> => {
  return API.delete(template(`/${id}`)).then(response => {
    alertSuccess(abbreviationsAPIMessages.deleted)
    return response
  })
}

const updateAbbreviation = async (id: string, body: AbbreviationModel, queryParams?: SimpleObject): Promise<any> => {
  return API.put(template(`/${id}`, queryParams), body).then(response => {
    alertSuccess(abbreviationsAPIMessages.updated)
    return response
  })
}

export const abbreviationService = {
  searchAbbreviations,
  createNewAbbreviation,
  deleteAbbreviation,
  updateAbbreviation,
  uploadAbbreviationFromFile,
}
