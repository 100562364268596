import React from 'react'
import styled from 'styled-components'

const TableCellDiv = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  word-break: 'break-all';
  align-items: center;
  &:last-child {
    max-width: 50px;
  }
  &:nth-last-child(2) {
    max-width: 150px;
  }
`

const TableCell: React.FC<{ style: any }> = ({ style, children }) => {
  return <TableCellDiv style={style}>{children}</TableCellDiv>
}

export default TableCell
