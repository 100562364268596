import { QuestionsDataResponse } from 'tabs/topQuestions/types/ApiResponses'
import { CLEAR_TOP_QUESTIONS_DATA, NEW_QUESTION_DATA_RECEIVED } from 'tabs/topQuestions/lib/questions_chart/questionCoordinatesSlice'

export const newQuestionDataReceived = (data: QuestionsDataResponse[]): NEW_QUESTION_DATA_RECEIVED => ({
  type: NEW_QUESTION_DATA_RECEIVED,
  payload: data,
})

export const clearTopQuestionsData = () => ({
  type: CLEAR_TOP_QUESTIONS_DATA
})
