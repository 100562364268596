import API from '../../../../../services/api'
import { alertSuccess } from '../../../../../api'
import { botSettingMessages, getApiUrlTemplateFunc } from '../../../api/configs'
import { Bot, BotFeedback, BotUser } from '../settings/state/BotSettingsTypes'
import { AddUsersToGroupAPI, GetUsersAPI, RemoveUserFromGroupAPI } from './types'

const template = getApiUrlTemplateFunc('v1/bot')

const updateFeedback = async (body: Partial<BotFeedback>, withAlert = true): Promise<any> => {
  return API.put(template('feedback/update'), body).then(response => {
    if (withAlert) alertSuccess(botSettingMessages.changed)
    return response
  })
}

const getBotSettings = async (): Promise<Bot> => {
  return API.get(template('feedback'))
}

const getBotUsers = async (params: GetUsersAPI): Promise<BotUser[]> => {
  const { searchName = '', limit = 100 } = params
  return API.get(template('user/all', { ...params, searchName, limit }))
}

const addUsersToGroup = async (body: AddUsersToGroupAPI): Promise<any> => {
  return API.post(template('user/feedback'), body)
}

const removeUserFromGroup = async (body: RemoveUserFromGroupAPI): Promise<any> => {
  return API.put(template('user/feedback/group'), body).then(response => {
    alertSuccess(botSettingMessages.groupRemoved)
    return response
  })
}

export const appBotService = {
  updateFeedback,
  getBotSettings,
  getBotUsers,
  addUsersToGroup,
  removeUserFromGroup,
}
