import { ClusterDetails } from 'tabs/topQuestions/types/InternalTypes'

export const getTopQuestionCount = (topQuestion: ClusterDetails,
                                    type: 'faq' | 'dax' | 'unknown') => {
  switch (type) {
    case 'faq':
      return topQuestion.faqCount
    case 'dax':
      return topQuestion.daxCount
    case 'unknown':
      return topQuestion.unknownCount
    default:
      return topQuestion.totalQuestionCount
  }
}
