import chroma from 'chroma-js'
import { ClusterDetailsState } from 'tabs/topQuestions/types/InternalTypes'

const colorWheelSize = 20
const colors = chroma.scale(['red', 'blue', 'yellow', 'green', 'purple']).colors(colorWheelSize)

export function updateClustersColors(clusters: ClusterDetailsState) {
  const keys = Object.keys(clusters)
  const size = keys.length
  for (let i = 0; i < size; i++) {
    const key = keys[i]
    const cluster = { ...clusters[key] }
    cluster.clusterColor = colors[i % colorWheelSize]
    clusters[key] = cluster
  }
  return clusters
}


