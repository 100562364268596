import { QuestionClusterItem } from 'tabs/topQuestions/components/question_cluster_item/QuestionClusterItem'
import React, { useState } from 'react'
import { ClusterDetails } from 'tabs/topQuestions/types/InternalTypes'
import { FetchClusterQuestions } from 'tabs/topQuestions/types/ApiResponses'
import { Pagination } from '@mui/lab'
import { UsePaginationProps } from '@mui/material/usePagination/usePagination'
import Card from '@mui/material/Card'
import { useSelector } from 'react-redux'
import './QuestionClusterList.scss'
import { selectClusterDetails } from 'tabs/topQuestions/lib/top_questions/topQuestionsSelectors'
import { getTopQuestionCount } from 'tabs/topQuestions/types/topQuestionsUtil'
import { selectQuestionTypeFilter } from 'tabs/topQuestions/lib/top_questions_search/topQuestionsSearchSelectors'

type QuestionClustersTabProps = {
  sendFetchClusterQuestionsMessage: (request: FetchClusterQuestions) => void,
}

const CLUSTERS_PAGE_SIZE = 10

export const QuestionClustersTab: React.FC<QuestionClustersTabProps> = ({
                                                                          sendFetchClusterQuestionsMessage,
                                                                        }) => {
  const topQuestions = useSelector(selectClusterDetails)

  const topQuestionTypeFilter = useSelector(selectQuestionTypeFilter)
  const [page, setPage] = useState(0)

  const questionsCountComparator = (cluster1: ClusterDetails, cluster2: ClusterDetails) => {
    const count1 = getTopQuestionCount(cluster1, topQuestionTypeFilter)
    const count2 = getTopQuestionCount(cluster2, topQuestionTypeFilter)
    if (count1 == undefined && count2 == undefined) {
      return 0
    }
    if (count1 != undefined && count2 == undefined) {
      return 1
    }
    if (count2 != undefined && count1 === undefined) {
      return -1
    }

    if (count1 < count2) {
      return 1
    }
    return -1
  }


  const [expandedClusterId, setExpandedClusterId] = useState<string | undefined>()
  const totalPages = Math.ceil(topQuestions.length / CLUSTERS_PAGE_SIZE)

  const onPageChange: UsePaginationProps['onChange'] = (_event, page) => {
    const zeroIndexedPage = page - 1
    setPage(zeroIndexedPage)
  }

  const filteredTopQuestions = topQuestions.filter(cluster => {
    const clusterCount = getTopQuestionCount(cluster, topQuestionTypeFilter)
    return clusterCount != 0
  })

  const sortedTopQuestions = filteredTopQuestions.sort(questionsCountComparator)
  const pageStart = page * CLUSTERS_PAGE_SIZE
  const pageEnd = (page + 1) * CLUSTERS_PAGE_SIZE
  const currentPageClusters = sortedTopQuestions
    .slice(pageStart, pageEnd)

  return <div className={'questionClustersWrapper'}>
    <Card className={'includedClustersTitleWrapper'}>
      <div className={'includedClustersWrapper'}>
        {currentPageClusters
          .map((topQuestion) => {
            return <QuestionClusterItem key={topQuestion.clusterId}
                                        topQuestion={topQuestion}
                                        expandedClusterId={expandedClusterId}
                                        setExpandedClusterId={setExpandedClusterId}
                                        sendFetchClusterQuestionsMessage={sendFetchClusterQuestionsMessage}
            />
          })}
        <div className={'paginationWrapper'}>
          <Pagination page={page + 1} onChange={onPageChange} count={totalPages} />
        </div>
      </div>
    </Card>
  </div>

}
