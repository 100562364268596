import { QuestionClusteringConfiguration, UpdateClusteringStatusPayload } from 'tabs/topQuestions/types/InternalTypes'
import { DEFAULT_CLUSTERING_SENSITIVITY } from 'tabs/topQuestions/constants/ClusteringConstants'
import { PayloadAction, RequestStatus } from 'tabs/topQuestions/types/GenericTypes'
import { FetchClusteringStatusResponse, StartClusteringResponse } from 'tabs/topQuestions/types/ApiResponses'
import { UPGRADE_TRAIN_CONFIGURATION } from 'tabs/topQuestions/lib/top_questions/topQuestionsConstnats'


export type ClusteringConfigurationSliceState = {
  startClusteringRequestStatus: RequestStatus
  clusteringConfiguration: QuestionClusteringConfiguration,
}

export const START_CLUSTERING_PENDING = 'clusteringConfiguration/START_CLUSTERING_PENDING'
export const START_CLUSTERING_SUCCESS = 'clusteringConfiguration/START_CLUSTERING_SUCCESS'
export const START_CLUSTERING_FAILED = 'clusteringConfiguration/START_CLUSTERING_FAILED'
export const UPDATE_CLUSTERING_STATUS = 'clusteringConfiguration/UPDATE_CLUSTERING_STATUS'
export const FETCH_CLUSTERING_STATUS_SUCCESS = 'clusteringConfiguration/FETCH_CLUSTERING_STATUS_SUCCESS'
export const FETCH_CLUSTERING_STATUS_FAILED = 'clusteringConfiguration/FETCH_CLUSTERING_STATUS_FAILED'


export type StartClusteringPending = PayloadAction<typeof START_CLUSTERING_PENDING, undefined>
export type StartClusteringSuccess = PayloadAction<typeof START_CLUSTERING_SUCCESS, StartClusteringResponse>
export type StartClusteringFailed = PayloadAction<typeof START_CLUSTERING_FAILED, undefined>

export type UpgradeTrainConfiguration = PayloadAction<typeof UPGRADE_TRAIN_CONFIGURATION, Partial<QuestionClusteringConfiguration>>
export type UpdateClusteringStatus = PayloadAction<typeof UPDATE_CLUSTERING_STATUS, UpdateClusteringStatusPayload>
export type FetchClusteringStatusSuccess = PayloadAction<typeof FETCH_CLUSTERING_STATUS_SUCCESS, FetchClusteringStatusResponse>
export type FetchClusteringStatusFailed = PayloadAction<typeof FETCH_CLUSTERING_STATUS_FAILED, string>


export type ClusteringConfigurationAction = UpgradeTrainConfiguration
  | StartClusteringPending
  | StartClusteringSuccess
  | StartClusteringFailed
  | UpdateClusteringStatus
  | FetchClusteringStatusSuccess
  | FetchClusteringStatusFailed

const createInitialState = (): ClusteringConfigurationSliceState => {
  return {
    startClusteringRequestStatus: RequestStatus.NONE,
    clusteringConfiguration: {
      clusterSensitivity: DEFAULT_CLUSTERING_SENSITIVITY,
    },
  }
}

export const clusteringConfigurationSlice = (
  state: ClusteringConfigurationSliceState = createInitialState(),
  action: ClusteringConfigurationAction): ClusteringConfigurationSliceState => {
  switch (action.type) {
    case UPGRADE_TRAIN_CONFIGURATION:
      return {
        ...state,
        clusteringConfiguration: {
          ...state.clusteringConfiguration,
          ...action.payload,
        },
      }
    case START_CLUSTERING_PENDING:
      return {
        ...state,
        startClusteringRequestStatus: RequestStatus.PENDING,
      }
    case START_CLUSTERING_SUCCESS:
      // status is updated on webhook request
      return state
    case UPDATE_CLUSTERING_STATUS:
      return {
        ...state,
        startClusteringRequestStatus: action.payload.status,

      }
    case FETCH_CLUSTERING_STATUS_SUCCESS:
      return {
        ...state,
        startClusteringRequestStatus: action.payload.status ? RequestStatus.PENDING : RequestStatus.NONE,
      }
    case START_CLUSTERING_FAILED:
      return {
        ...state,
        startClusteringRequestStatus: RequestStatus.REJECTED,
      }
    default:
      return state
  }
}

export const selectClusteringSensitivityConfiguration = (store: any): number => {
  const clusteringConfigState: ClusteringConfigurationSliceState = store.clusteringConfigurationSlice
  return clusteringConfigState.clusteringConfiguration.clusterSensitivity
}

export const selectClusteringRequestStatus = (store: any) => {
  const clusteringConfigState: ClusteringConfigurationSliceState = store.clusteringConfigurationSlice
  return clusteringConfigState.startClusteringRequestStatus
}

export const updateTrainingConfiguration = (payload: Partial<QuestionClusteringConfiguration>): UpgradeTrainConfiguration => ({
  type: UPGRADE_TRAIN_CONFIGURATION,
  payload,
})

export const updateClusteringStatus = (payload: UpdateClusteringStatusPayload): UpdateClusteringStatus => ({
  type: UPDATE_CLUSTERING_STATUS,
  payload,
})
